import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import {
  CreateNewsGroupFormType,
  NewsGroupStatusType,
  NewsGroupType,
} from "../../types/groups";
import {
  STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_GTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_LTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_GTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_LTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_GTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_LTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_GTE,
  STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_LTE,
  STORAGE_KEY_FILTER_GROUP_SEARCH,
  STORAGE_KEY_FILTER_GROUP_STATUS,
} from "../../features/constants";
import { OptionType } from "../../types/common";
import { ProjectNewsType } from "../../types/project-news";
import { MultiValue } from "react-select";

interface GroupsProcessingState {
  isOpenModal: boolean;
  project_news: ProjectNewsType | null;
  currentGroup: NewsGroupType | null;
  createNewsForm: CreateNewsGroupFormType;
  news_count_gte: number | null;
  news_count_lte: number | null;
  statuses: MultiValue<
    OptionType<string, NewsGroupStatusType | string> | undefined
  >;
  search: string;
  created__gte: string;
  created__lte: string;
  updated__gte: string;
  updated__lte: string;
  publish_datetime_gte: string;
  publish_datetime_lte: string;
}

const initialState: GroupsProcessingState = {
  isOpenModal: false,
  project_news: null,
  currentGroup: null,
  news_count_gte:
    Number(localStorage.getItem(STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_GTE)) ||
    null,
  news_count_lte:
    Number(localStorage.getItem(STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_LTE)) ||
    null,
  createNewsForm: {
    participants: "",
    picture: "",
    tags: [],
    text: "",
  },
  statuses: JSON.parse(localStorage.getItem(STORAGE_KEY_FILTER_GROUP_STATUS)!),
  search: localStorage.getItem(STORAGE_KEY_FILTER_GROUP_SEARCH) || "",
  created__gte: "",
  created__lte: "",
  updated__gte: "",
  updated__lte: "",
  publish_datetime_gte: "",
  publish_datetime_lte: "",
};

export const groupsProcessingSlice = createSlice({
  name: "groups-processing",
  initialState,
  reducers: {
    setIsOpenModalProcessing: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setCurrentGroup: (state, action: PayloadAction<NewsGroupType | null>) => {
      state.currentGroup = action.payload;
    },
    setProjectNews: (state, action: PayloadAction<ProjectNewsType | null>) => {
      state.project_news = action.payload;
    },
    setGroupFilterStatus: (
      state,
      action: PayloadAction<
        MultiValue<OptionType<string, NewsGroupStatusType | string> | undefined>
      >
    ) => {
      state.statuses = action.payload as any;
    },
    setGroupFilterSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setNewsCountGte: (state, action: PayloadAction<number>) => {
      state.news_count_gte = action.payload;
    },
    setNewsCountLte: (state, action: PayloadAction<number>) => {
      state.news_count_lte = action.payload;
    },
    setCreatedGte: (state, action: PayloadAction<string>) => {
      state.created__gte = action.payload;
    },
    setCreatedLte: (state, action: PayloadAction<string>) => {
      state.created__lte = action.payload;
    },
    setUpdatedGte: (state, action: PayloadAction<string>) => {
      state.updated__gte = action.payload;
    },
    setUpdatedLte: (state, action: PayloadAction<string>) => {
      state.updated__lte = action.payload;
    },
    setPublishDatetimeGte: (state, action: PayloadAction<string>) => {
      state.publish_datetime_gte = action.payload;
    },
    setPublishDatetimeLte: (state, action: PayloadAction<string>) => {
      state.publish_datetime_lte = action.payload;
    },
  },
});

export const groupsProcessingActions = groupsProcessingSlice.actions;

export const selectGroupsProcessing = (state: RootState) => state.processing;

export default groupsProcessingSlice.reducer;
