import { AxiosError, AxiosResponse } from 'axios';
import { instance } from '../instance';
import { CreateUserType, GetUsersType, UpdateUserType, UserType } from '../types/users';

export const userService = {
  getMe: async () => {
    return instance.get<UserType>('/users/me/').then((res) => res.data);
  },
  getUsers: async ({ page, limit }: { page: number; limit: number }) => {
    return instance
      .get<GetUsersType>('/users/', { params: { limit, offset: (page - 1) * limit } })
      .then((res) => res.data);
  },
  createUser: async (body: CreateUserType) => {
    try {
      const response = await instance.post<
        CreateUserType,
        AxiosResponse<UserType> | AxiosError<{ [key: string]: string[] }>
      >('/users/', body);

      if (response instanceof AxiosError) {
        return {
          success: false,
          detail: response.response?.data,
        };
      }

      return {
        success: true,
        data: response.data,
      };
    } catch (error) {
      throw error;
    }
  },
  updateUser: async (id: number, body: UpdateUserType) => {
    return instance
      .patch<UpdateUserType, AxiosResponse<any>>(`/users/${id}/`, body)
      .then((res) => res.data);
  },
  removeUser: async (id: number) => {
    return instance.delete(`/users/${id}/`).then((res) => res.data);
  },
};
