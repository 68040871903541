import { useDispatch, useSelector } from 'react-redux';
import type { TypedUseSelectorHook } from 'react-redux';

import { useMemo } from 'react';
import { bindActionCreators } from '@reduxjs/toolkit';

import { authActions } from '../../slices/new/auth';
import { newsActions } from '../../slices/new/news';
import { groupsProcessingActions } from '../../slices/new/group-processing';
import { projectActions } from '../../slices/new/project';
import { usersActions } from '../../slices/new/users';
import { tgActions } from './../../slices/new/tg';
import { rssActions } from './../../slices/new/rss';
import { tagActions } from './../../slices/new/tag';
import { proxyActions } from './../../slices/new/proxy';
import { groupsActions } from './../../slices/new/groups';

import { AppDispatch, RootState } from '../../slices';

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

const allActions = {
  ...authActions,
  ...projectActions,
  ...groupsProcessingActions,
  ...usersActions,
  ...newsActions,
  ...rssActions,
  ...tgActions,
  ...tagActions,
  ...proxyActions,
  ...groupsActions
};

export const useActions = () => {
  const dispatch = useAppDispatch();
  return useMemo(() => bindActionCreators(allActions, dispatch), [dispatch]);
};
