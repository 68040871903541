import React, { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";

import "./assets/scss/themes.scss";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./routes";

import fakeBackend from "./helpers/AuthType/fakeBackend";
import Loader from "./Components/Common/Loader";

fakeBackend();

const queryClient = new QueryClient();

function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <Suspense fallback={<Loader />}>
          <Routes />
        </Suspense>
        <ToastContainer />
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
