import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { ProxyType } from '../../types/proxies';

interface ProxyState {
  isOpenModal: boolean;
  proxy: ProxyType | null;
}

const initialState: ProxyState = {
  isOpenModal: false,
  proxy: null,
};

export const proxySlice = createSlice({
  name: 'proxy',
  initialState,
  reducers: {
    setIsOpenProxyModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setCurrentProxy: (state, action: PayloadAction<ProxyType>) => {
      state.proxy = action.payload;
    },
  },
});

export const proxyActions = proxySlice.actions;

export const selectProxy = (state: RootState) => state.proxy;

export default proxySlice.reducer;
