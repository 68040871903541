import axios, { AxiosError } from "axios";
import Cookies from "js-cookie";
import { authService } from "./service/auth";
import config from "../config";
import { toast } from "react-toastify";

export const instance = axios.create({
  baseURL: config.api.API_URL,
});

instance.interceptors.request.use(
  (conf) => {
    const accessToken = Cookies.get(config.cookie.ACCESS_KEY);

    if (accessToken) {
      conf.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return conf;
  },
  (error) => {
    return Promise.reject(error);
  }
);

let isRetry: boolean = false;

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error: AxiosError) => {
    const { config: originalReq, response } = error;

    if (response?.status === 401) {
      Cookies.remove(config.cookie.ACCESS_KEY);
      Cookies.remove(config.cookie.REFRESH_KEY);
      window.location.pathname = "/login";
    }

    if (response?.status !== 401 && response?.status !== 403) {
      if (response && response.data && (response.data as any).detail) {
        toast((response?.data as any).detail, { type: "error" });
      }
    }

    if (
      error.response?.status === 403 &&
      originalReq?.url !== "/token/refresh/" &&
      !isRetry
    ) {
      const token = Cookies.get(config.cookie.REFRESH_KEY);

      if (token) {
        isRetry = true;

        try {
          const data = await authService.refreshToken(token);

          if (data.access) {
            Cookies.set(config.cookie.ACCESS_KEY, data.access);

            // Update the original request with the new token
            originalReq!.headers.Authorization = `Bearer ${data.access}`;
        
            // Send the new request
            const newResponse = await instance(originalReq!)

            isRetry = false;

            return newResponse;
          }
        } catch (error) {
          Cookies.remove(config.cookie.ACCESS_KEY);
          Cookies.remove(config.cookie.REFRESH_KEY);
        } finally {
          isRetry = false;
        }
      }
    }

    return Promise.reject(error);
  }
);
