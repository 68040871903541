import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import { OptionType } from "../../types/common";

interface AuthState {
  groups: number[];
  action: OptionType<string, string | null>;
}

const initialState: AuthState = {
  groups: [],
  action: {
    label: "Выберите действие",
    value: null,
  },
};

export const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    selectGroup: (state, action: PayloadAction<number>) => {
      state.groups = [...state.groups, action.payload];
    },
    removeGroup: (state, action: PayloadAction<number>) => {
      state.groups = state.groups.filter((id) => id !== action.payload);
    },
    removeAll: (state) => {
      state.groups = [];
    },
    setManyGroups: (state, action: PayloadAction<number[]>) => {
      state.groups = action.payload;
    },
    setActionGroups: (
      state,
      action: PayloadAction<OptionType<string, string | null>>
    ) => {
      state.action = action.payload;
    },
  },
});

export const groupsActions = groupsSlice.actions;

export const selectGroups = (state: RootState) => state.groups;

export default groupsSlice.reducer;
