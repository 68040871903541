import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { CreateOrUpdateUserType } from '../../types/users';

interface UsersState {
  form: CreateOrUpdateUserType;
  isOpenUsersModal: boolean;
  currentUserId: number | null;
}

const initialState: UsersState = {
  form: {
    is_superuser: false,
    email: '',
    password: '',
    project_ids: [],
    is_active: {
      label: 'Не активен',
      value: false,
    },
  },
  isOpenUsersModal: false,
  currentUserId: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setIsOpenUsersModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenUsersModal = action.payload;
    },
    setUserForm: (state, action: PayloadAction<CreateOrUpdateUserType>) => {
      state.form = action.payload;
    },
    setCurrentUserId: (state, action: PayloadAction<number | null>) => {
      state.currentUserId = action.payload;
    },
    resetTheFormUsers: (state) => {
      state.form = {
        is_superuser: false,
        email: '',
        password: '',
        project_ids: [],
        is_active: {
          label: 'Не активен',
          value: false,
        },
      };
    },
  },
});

export const usersActions = usersSlice.actions;

export const selectUsers = (state: RootState) => state.users;

export default usersSlice.reducer;
