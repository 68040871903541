import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import Cookies from 'js-cookie';
import config from '../../config';
import { UserType } from '../../api/types/users';

interface AuthState {
  isAuth: boolean;
  authForm: {
    email: string;
    password: string;
  };
  user: UserType | null;
}

const initialState: AuthState = {
  isAuth: false,
  authForm: {
    email: '',
    password: '',
  },
  user: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    logout: (state) => {
      state.isAuth = false;
      Cookies.remove(config.cookie.ACCESS_KEY);
      Cookies.remove(config.cookie.REFRESH_KEY);
    },
    setForm: (state, action: PayloadAction<AuthState['authForm']>) => {
      state.authForm = action.payload;
    },
    setAuthUser: (state, action: PayloadAction<UserType>) => {
      state.user = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
