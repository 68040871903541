import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { RSSSourceType } from '../../types/sources';

interface RSSState {
  isOpenModal: boolean;
  rss: RSSSourceType | null;
}

const initialState: RSSState = {
  isOpenModal: false,
  rss: null,
};

export const rssSlice = createSlice({
  name: 'rss',
  initialState,
  reducers: {
    setIsOpenRSSModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setCurrentRSS: (state, action: PayloadAction<RSSSourceType>) => {
      state.rss = action.payload;
    },
  },
});

export const rssActions = rssSlice.actions;

export const selectRSS = (state: RootState) => state.rss;

export default rssSlice.reducer;
