import React from 'react';
import { Spinner } from 'reactstrap';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Loader = ({ fullScreen = true, ...props }) => {
  return (
    <React.Fragment>
      <div
        style={{ height: fullScreen ? '100vh' : '100%' }}
        className="d-flex items-center justify-content-center"
      >
        <Spinner color="primary"> Loading... </Spinner>
      </div>
      {toast.error(props.error, {
        position: 'top-right',
        hideProgressBar: false,
        progress: undefined,
        toastId: '',
      })}
    </React.Fragment>
  );
};

export default Loader;
