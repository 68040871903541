import React, { useEffect } from 'react';
import withRouter from '../Components/Common/withRouter';

//redux
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import config from '../config';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';

const NonAuthLayout = ({ children }) => {
    const navigate = useNavigate()
    const token = Cookies.get(config.cookie.ACCESS_KEY);

    const nonauthData = createSelector(
        (state) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(nonauthData);

    useEffect(() => {
        if (layoutModeType === "dark") {
            document.body.setAttribute("data-bs-theme", "dark");
        } else {
            document.body.setAttribute("data-bs-theme", "light");
        }
        return () => {
            document.body.removeAttribute("data-bs-theme");
        };
    }, [layoutModeType]);

    useEffect(() => {
        if (token) {
            navigate('/projects')
        }
    }, [token]);

    return (
        <div>
            {children}
        </div>
    );
};

export default withRouter(NonAuthLayout);