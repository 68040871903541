import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { TGSourceType } from '../../types/sources';

interface TGState {
  isOpenModal: boolean;
  tg: TGSourceType | null;
}

const initialState: TGState = {
  isOpenModal: false,
  tg: null,
};

export const tgSlice = createSlice({
  name: 'tg',
  initialState,
  reducers: {
    setIsOpenTGModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setCurrentTG: (state, action: PayloadAction<TGSourceType>) => {
      state.tg = action.payload;
    },
  },
});

export const tgActions = tgSlice.actions;

export const selectTG = (state: RootState) => state.tg;

export default tgSlice.reducer;
