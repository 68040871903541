import { useQuery } from '@tanstack/react-query';
import { QUERY_KEY_GET_ME } from '../../features/constants';
import { userService } from '../service/users';

export const useGetMe = (enable: boolean = true) => {
  return useQuery({
    queryKey: [QUERY_KEY_GET_ME],
    queryFn: userService.getMe,
    enabled: enable,
  });
};
