import { NewsGroupStatusType } from "../types/groups";
import { ProjectNewsStatutes } from "../types/project-news";

export const QUERY_KEY_GET_PROJECTS = "QUERY_KEY_GET_PROJECTS";
export const QUERY_KEY_GET_LOGS = "QUERY_KEY_GET_LOGS";
export const QUERY_KEY_GET_PROJECT_NEWS_TAGS =
  "QUERY_KEY_GET_PROJECT_NEWS_TAGS";
export const QUERY_KEY_GET_PROJECT_NEWS_MEMBERS =
  "QUERY_KEY_GET_PROJECT_NEWS_MEMBERS";
export const QUERY_KEY_GET_PROJECT_NEWS_MARKETS =
  "QUERY_KEY_GET_PROJECT_NEWS_MARKETS";
export const QUERY_KEY_GET_PROXIES = "QUERY_KEY_GET_PROXIES";
export const QUERY_KEY_GET_ME = "QUERY_KEY_GET_ME";
export const QUERY_KEY_GET_PROJECT_NEWS = "QUERY_KEY_GET_PROJECT_NEWS";
export const QUERY_KEY_GET_USERS = "QUERY_KEY_GET_USERS";
export const QUERY_KEY_GET_TAGS = "QUERY_KEY_GET_TAGS";
export const QUERY_KEY_GET_TG_SOURCES = "QUERY_KEY_GET_TG_SOURCES";
export const QUERY_KEY_GET_RSS_SOURCES = "QUERY_KEY_GET_RSS_SOURCES";
export const QUERY_KEY_GET_GROUPS = "QUERY_KEY_GET_GROUPS";
export const QUERY_KEY_GET_NEWS_GROUP = "QUERY_KEY_GET_NEWS_GROUP";
export const QUERY_KEY_GET_PROJECT_NEWS_BY_ID =
  "QUERY_KEY_GET_PROJECT_NEWS_BY_ID";

export const QUERY_KEY_GET_ADMINTOOLS = "QUERY_KEY_GET_ADMINTOOLS";

export const STORAGE_KEY_MODE = "STORAGE_KEY_MODE";
export const STORAGE_KEY_FILTER_NEWS_SEARCH = "STORAGE_KEY_FILTER_NEWS_SEARCH";
export const STORAGE_KEY_FILTER_NEWS_STATUS = "STORAGE_KEY_FILTER_NEWS_STATUS";
export const STORAGE_KEY_FILTER_PROJECT_TAGS =
  "STORAGE_KEY_FILTER_PROJECT_TAGS";
export const STORAGE_KEY_FILTER_PROJECT_SEARCH =
  "STORAGE_KEY_FILTER_PROJECT_SEARCH";

export const STORAGE_KEY_FILTER_GROUP_SEARCH =
  "STORAGE_KEY_FILTER_GROUP_SEARCH";
export const STORAGE_KEY_FILTER_GROUP_STATUS =
  "STORAGE_KEY_FILTER_GROUP_STATUS";
export const STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_GTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_GTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_LTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_COUNT_LTE";

export const STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_GTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_GTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_LTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_CREATED_LTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_GTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_GTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_LTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_UPDATED_LTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_GTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_GTE";
export const STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_LTE =
  "STORAGE_KEY_FILTER_GROUP_NEWS_PUBLISHED_DATETIME_LTE";

export const projectNewsStatuses: { [key in ProjectNewsStatutes]: string } = {
  editing: "На редактировании",
  delayed: "Запланирован",
  posted: "Опубликован",
};

export const groupStatuses: { [key in NewsGroupStatusType]: string } = {
  editing: "На редактировании",
  new: "Новый",
  not_relevant: "Не актуально",
  processed: "Обработан",
  error: "Произошла ошибка",
};
