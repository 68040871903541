import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import {
  ProjectBasicInfoType,
  ProjectChatGPTParameters,
  ProjectFiltersType,
  ProjectSourceType,
} from '../../types/projects';
import { OptionType } from '../../types/common';
import {
  STORAGE_KEY_FILTER_PROJECT_SEARCH,
  STORAGE_KEY_FILTER_PROJECT_TAGS,
} from '../../features/constants';

interface ProjectState {
  isOpenProjectsModal: boolean;
  basic_info: ProjectBasicInfoType;
  sources: ProjectSourceType;
  chatgpt_parameters: ProjectChatGPTParameters;
  filters: ProjectFiltersType;
  proxy: string;
  currentProjectId: number | null;
  filter_tags: OptionType<string, number>[];
  filter_search: string;
  news_prompt: string;
}

const initialState: ProjectState = {
  isOpenProjectsModal: false,
  basic_info: {
    title: '',
    frequency_parsing: 0,
    tags: [],
    tg_chat_id: '',
    match_percentage_body: 0,
    match_percentage_title_description_tags: 0,
    rssapp_secret: '',
    rssapp_key: '',
  },
  sources: {
    rss: '',
    tg: '',
  },
  chatgpt_parameters: {
    conclusion_characters_max: 0,
    conclusion_characters_min: 0,
    members_number: 0,
    return_characters_max: 0,
    return_characters_min: 0,
    tags_number: 0,
  },
  filters: {
    key_words: [],
    stop_words: [],
  },
  news_prompt: "",
  proxy: '',
  currentProjectId: null,
  filter_search: localStorage.getItem(STORAGE_KEY_FILTER_PROJECT_SEARCH)! || '',
  filter_tags: JSON.parse(localStorage.getItem(STORAGE_KEY_FILTER_PROJECT_TAGS)!) || [],
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setIsOpenProjectsModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenProjectsModal = action.payload;
    },
    setBasicInfo: (state, action: PayloadAction<ProjectBasicInfoType>) => {
      state.basic_info = action.payload;
    },
    setSources: (state, action: PayloadAction<ProjectSourceType>) => {
      state.sources = action.payload;
    },
    setChatGPTParameters: (state, action: PayloadAction<ProjectChatGPTParameters>) => {
      state.chatgpt_parameters = action.payload;
    },
    setFilters: (state, action: PayloadAction<ProjectFiltersType>) => {
      state.filters = action.payload;
    },
    setProxy: (state, action: PayloadAction<string>) => {
      state.proxy = action.payload;
    },
    setProjectFilterTags: (
      state,
      action: PayloadAction<OptionType<string, number>[]>,
    ) => {
      state.filter_tags = action.payload;
    },
    setNewsPrompt: (state, action: PayloadAction<string>) => {
      state.news_prompt = action.payload;
    },
    setProjectFilterSearch: (state, action: PayloadAction<string>) => {
      state.filter_search = action.payload;
    },
    setCurrentProjectId: (state, action: PayloadAction<number>) => {
      state.currentProjectId = action.payload;
    },
    resetTheFormProject: (state) => {
      state.basic_info = {
        title: '',
        frequency_parsing: 0,
        tags: [],
        tg_chat_id: '',
        match_percentage_body: 0,
        match_percentage_title_description_tags: 0,
        rssapp_key: '',
        rssapp_secret: '',
      };
      state.sources = {
        rss: '',
        tg: '',
      };
      state.chatgpt_parameters = {
        conclusion_characters_max: 0,
        conclusion_characters_min: 0,
        members_number: 0,
        return_characters_max: 0,
        return_characters_min: 0,
        tags_number: 0,
      };
      state.filters = {
        key_words: [],
        stop_words: [],
      };
      state.proxy = '';
      state.currentProjectId = null;
    },
  },
});

export const projectActions = projectSlice.actions;

export const selectProject = (state: RootState) => state.project;

export default projectSlice.reducer;
