import {
  GetAccessTokenBody,
  GetAccessTokenResType,
  UpdateAccessTokenResType,
} from './../types/auth';
import { instance } from '../instance';
import { AxiosResponse } from 'axios';


export const authService = {
  async getAccessToken(body: GetAccessTokenBody) {
    return instance.post<GetAccessTokenBody, AxiosResponse<GetAccessTokenResType | string>>('/users/token/', body).then((res: any) => {
      if (res.status === 200) {
        return { access: res.data.access, refresh: res.data.refresh, status: res.status };
      }
      return { detail: res.response.data.detail, status: res.response.status }
    });
  },
  async refreshToken(token: string) {
    return instance
      .post<UpdateAccessTokenResType>('/users/token/refresh/', { refresh: token })
      .then((res) => res.data);
  },
};
