import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";

interface AuthState {
  text: string;
}

const initialState: AuthState = {
  text: "",
};

export const groupFormSlice = createSlice({
  name: "group-slice",
  initialState,
  reducers: {
    setTextGroupForm: (state, action: PayloadAction<string>) => {
      state.text = action.payload;
    },
  },
});

export const groupFormActions = groupFormSlice.actions;

export const selectGroupForm = (state: RootState) => state.groupForm;

export default groupFormSlice.reducer;
