import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { OptionType } from '../../types/common';

interface RSSState {
  isOpenForm: boolean;
  currentTag: OptionType<string, number> | null;
  tag_title: string;
}

const initialState: RSSState = {
  isOpenForm: false,
  currentTag: null,
  tag_title: '',
};

export const tagSlice = createSlice({
  name: 'tag',
  initialState,
  reducers: {
    setIsOpenFormTag: (state, action: PayloadAction<boolean>) => {
      state.isOpenForm = action.payload;
    },
    updateTitleTag: (state, action: PayloadAction<string>) => {
      state.tag_title = action.payload;
    },
    setCurrentTag: (state, action: PayloadAction<OptionType<string, number> | null>) => {
      state.currentTag = action.payload;
    },
  },
});

export const tagActions = tagSlice.actions;

export const selectTag = (state: RootState) => state.tag;

export default tagSlice.reducer;
