import React, { useEffect } from "react";
import { Route, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import config from "../config";
import { useGetMe } from "../api/hooks/useGetMe";
import { useActions } from "../features/hooks/useRedux";
import Loader from "../Components/Common/Loader";

const AuthProtected = (props: any) => {
  const navigate = useNavigate();
  const token = Cookies.get(config.cookie.ACCESS_KEY);
  const { data, isLoading } = useGetMe();
  const { setAuthUser } = useActions();

  useEffect(() => {
    if (!token) {
      navigate("/login");
    }
  }, [token]);

  useEffect(() => {
    if (data && "email" in data) {
      setAuthUser(data);
    }
  }, [data, setAuthUser]);

  if (isLoading) return <Loader />;

  return <>{props.children}</>;
};

const AccessRoute = ({ component: Component, ...rest }: { component: any }) => {
  return (
    <Route
      {...rest}
      // @ts-ignore
      render={(props) => {
        return (
          <>
            <Component {...props} />
          </>
        );
      }}
    />
  );
};

export { AuthProtected, AccessRoute };
