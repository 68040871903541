
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

const language = localStorage.getItem("I18N_LANGUAGE");
if (!language) {
  localStorage.setItem("I18N_LANGUAGE", "en");
}

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    lng: localStorage.getItem("I18N_LANGUAGE") || "en",
    debug: true,
    fallbackLng: ["en", "ru"],
    keySeparator: ".",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

