import React from "react";
import { Navigate } from "react-router-dom";
import SuperUserProtection from "../Layouts/SuperUserProtection";

const LoginPage = React.lazy(() => import("../pages/auth/Login"));
const ProjectsPage = React.lazy(() => import("../pages/projects/index"));
const GroupsPage = React.lazy(() => import("../pages/groups/index"));
const NewsPage = React.lazy(() => import("../pages/news/index"));
const LogsPage = React.lazy(() => import("../pages/logs/index"));
const UsersPage = React.lazy(() => import("../pages/users/index"));
const AdmintoolsPage = React.lazy(() => import("../pages/admintools/index"));

const authProtectedRoutes = [
  {
    path: "/news",
    exact: true,
    component: <NewsPage />,
  },
  {
    path: "/projects",
    exact: true,
    component: (
      <SuperUserProtection>
        <ProjectsPage />
      </SuperUserProtection>
    ),
  },
  {
    path: "/groups",
    exact: true,
    component: <GroupsPage />,
  },
  {
    path: "/users",
    exact: true,
    component: (
      <SuperUserProtection>
        <UsersPage />
      </SuperUserProtection>
    ),
  },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/projects" />,
  },
  {
    path: "/logs",
    exact: true,
    component: <LogsPage />,
  },
  {
    path: "/admintools",
    exact: true,
    component: <AdmintoolsPage />,
  },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <LoginPage /> },
];

export { authProtectedRoutes, publicRoutes };
