import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from '../features/hooks/useRedux';
import { selectAuth } from '../slices/new/auth';
import Loader from '../Components/Common/Loader';

export default function SuperUserProtection({ children }: { children: React.ReactNode }) {
  const { user } = useAppSelector(selectAuth);

  if (user && !user?.is_superuser) {
    return <Navigate to={'/groups'} />;
  }

  if (!user) {
    return <Loader />;
  }

  return <>{children}</>;
}
