import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '..';
import { ProjectNewsStatutes, ProjectNewsType } from '../../types/project-news';
import { OptionType } from '../../types/common';
import {
  STORAGE_KEY_FILTER_NEWS_SEARCH,
  STORAGE_KEY_FILTER_NEWS_STATUS,
} from '../../features/constants';

interface NewsState {
  isOpenNewsModal: boolean;
  currentNews: ProjectNewsType | null;
  status: OptionType<string, ProjectNewsStatutes> | undefined;
  search: string;
}

const initialState: NewsState = {
  isOpenNewsModal: false,
  currentNews: null,
  status: JSON.parse(localStorage.getItem(STORAGE_KEY_FILTER_NEWS_STATUS)!) || undefined,
  search: localStorage.getItem(STORAGE_KEY_FILTER_NEWS_SEARCH) || '',
};

export const newsSlice = createSlice({
  name: 'news',
  initialState,
  reducers: {
    setIsOpenNewsModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenNewsModal = action.payload;
    },
    setCurrentNews: (state, action: PayloadAction<ProjectNewsType | null>) => {
      state.currentNews = action.payload;
    },
    setProjectNewsStatusFilter(
      state,
      action: PayloadAction<OptionType<string, ProjectNewsStatutes> | undefined>,
    ) {
      state.status = action.payload;
    },
    setProjectNewsSearchFilter(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
  },
});

export const newsActions = newsSlice.actions;

export const selectNews = (state: RootState) => state.news;

export default newsSlice.reducer;
